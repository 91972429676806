var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h1',[_vm._v("Romaneios de entregas")]),_c('v-btn',{staticClass:"ma-2",attrs:{"color":"error"},on:{"click":function($event){return _vm.$router.back()}}},[_c('v-icon',[_vm._v("mdi-keyboard-return")])],1),_c('v-btn',{staticClass:"ma-2",attrs:{"color":"warning"},on:{"click":function($event){return _vm.$router.push('/ctes')}}},[_vm._v(" ctes ")]),_c('v-btn',{staticClass:"ma-2",attrs:{"color":"success"},on:{"click":function($event){return _vm.$router.push('/criar-romaneio')}}},[_c('v-icon',[_vm._v("mdi-plus")])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('div',[_c('v-date-picker',{attrs:{"landscape":"","locale":"pt-br","disabled":_vm.loading,"no-title":""},model:{value:(_vm.dateFrom),callback:function ($$v) {_vm.dateFrom=$$v},expression:"dateFrom"}}),_c('v-date-picker',{attrs:{"landscape":"","locale":"pt-br","disabled":_vm.loading,"no-title":""},model:{value:(_vm.dateTo),callback:function ($$v) {_vm.dateTo=$$v},expression:"dateTo"}})],1)]),_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('v-data-table',{staticClass:"my-4",attrs:{"items":_vm.deliveries,"headers":_vm.headers,"disable-pagination":"","hide-default-footer":"","loading":_vm.loading},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("moment")(item.date,"DD/MM/YYYY")))])]}},{key:"item.notDelivered",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.handleStatus(item)[1]))])]}},{key:"item.delivered",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.handleStatus(item)[0]))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"d-flex flex-wrap"},[_c('v-icon',{attrs:{"color":"warning"},on:{"click":function($event){return _vm.$router.push('/editar-romaneio/' + item.id)}}},[_vm._v("mdi-pencil")]),_c('delete-delivery',{attrs:{"delivery":item},on:{"removed":_vm.getDeliveries}})],1)]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }