<template>
  <div>
    <v-icon color="red" @click="deleteDelivery">mdi-delete</v-icon>
  </div>
</template>

<script>
export default {
  props: {
    delivery: {
      type: Object,
      default: null,
    },
  },
  methods: {
    deleteDelivery() {
      this.$confirm({
        type: "error",
        title: "Excluir Romaneio",
        content: "Tem certeza que deseja excluir este romaneio?",
        confirmText: "Sim",
        cancelText: "Não",
        confirm: () => {
          this.$http
            .delete("api/deliveries/" + this.delivery.id)
            .then(() => {
              this.$side({
                type: "success",
                msg: "Removido com sucesso",
                duration: 4000,
              });
              this.$emit("removed");
            })
            .catch((err) => {
              this.$side({
                type: "error",
                msg: "Erro ao remover do romaneio",
                duration: 4000,
              });
              console.log(err);
            });
          this.$close(); //If you don't want to close the dialog, you can not use it.
        },
        cancel: () => {
          console.log("Your callback");
          this.$close(); //If you don't want to close the dialog, you can not use it.
        },
      });
    },
  },
};
</script>

<style scoped></style>
