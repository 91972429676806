<template>
  <div>
    <h1>Romaneios de entregas</h1>

    <v-btn @click="$router.back()" class="ma-2" color="error">
      <v-icon>mdi-keyboard-return</v-icon>
    </v-btn>

    <v-btn @click="$router.push('/ctes')" class="ma-2" color="warning">
      ctes
    </v-btn>

    <v-btn
      @click="$router.push('/criar-romaneio')"
      class="ma-2"
      color="success"
    >
      <v-icon>mdi-plus</v-icon>
    </v-btn>

    <v-row>
      <v-col cols="12" md="4">
        <div>
          <v-date-picker
            landscape
            locale="pt-br"
            v-model="dateFrom"
            :disabled="loading"
            no-title
          ></v-date-picker>
          <v-date-picker
            landscape
            locale="pt-br"
            v-model="dateTo"
            :disabled="loading"
            no-title
          ></v-date-picker>
        </div>
      </v-col>

      <v-col cols="12" md="8">
        <v-data-table
          class="my-4"
          :items="deliveries"
          :headers="headers"
          disable-pagination
          hide-default-footer
          :loading="loading"
        >
          <template v-slot:[`item.date`]="{ item }">
            <span>{{ item.date | moment("DD/MM/YYYY") }}</span>
          </template>

          <template v-slot:[`item.notDelivered`]="{ item }">
            <span>{{ handleStatus(item)[1] }}</span>
          </template>

          <template v-slot:[`item.delivered`]="{ item }">
            <span>{{ handleStatus(item)[0] }}</span>
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <span class="d-flex flex-wrap">
              <v-icon
                color="warning"
                @click="$router.push('/editar-romaneio/' + item.id)"
                >mdi-pencil</v-icon
              >
              <delete-delivery @removed="getDeliveries" :delivery="item" />
            </span>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import http from "@/modules/http";
import moment from "moment";
import DeleteDelivery from "../components/DeleteDelivery";

export default {
  components: {
    DeleteDelivery,
  },
  data() {
    return {
      loading: false,
      dateFrom:
        this.$store.state.dateDeliveries.dateFrom ??
        new Date().toISOString().slice(0, 10),
      dateTo:
        this.$store.state.dateDeliveries.dateTo ??
        new Date().toISOString().slice(0, 10),
      deliveries: [],
      headers: [
        { text: "ID", value: "id" },
        { text: "Data", value: "date" },
        { text: "Motorista", value: "drivers.name" },
        { text: "Entregue", value: "delivered" },
        { text: "Não Entregue", value: "notDelivered" },
        { text: "Ações", value: "actions" },
      ],
    };
  },
  watch: {
    dateFrom() {
      this.getDeliveries();
      this.$store.dispatch("set_date_deliveries", [this.dateFrom, this.dateTo]);
    },
    dateTo() {
      this.getDeliveries();
      this.$store.dispatch("set_date_deliveries", [this.dateFrom, this.dateTo]);
    },
  },
  methods: {
    handleStatus(ctes) {
      let delivered = 0;
      let notDelivered = 0;

      ctes.delivery_ctes.forEach((cte) => {
        if (cte.ctes.delivered) {
          delivered++;
        } else {
          notDelivered++;
        }
      });

      return [delivered, notDelivered];
    },
    getDeliveries() {
      this.loading = true;
      http
        .get(
          "api/deliveries?full=true&from=" +
            this.dateFrom +
            "&to=" +
            this.dateTo
        )
        .then((res) => {
          this.loading = false;
          this.deliveries = res.data;
        });
    },
  },
  mounted() {
    this.getDeliveries();
  },
};
</script>

<style scoped></style>
